.square {
	position: relative;
	width: 100%;
	align-items: center;
	justify-content: center;
	display:flex;
}

.square:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.content {
	position: absolute;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.main-modal {
	min-width: 90%;
}

.carousel-indicators li {
	background-color: "#0171bb";
}